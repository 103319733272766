<template>
  <VueSimpleSuggest
    :filter-by-query="false"
    ref="suggestComponent"
    :list="getList"
    mode="input"
    v-model="valueChild"
    @suggestion-click="suggClick($event)"
    :debounce="300"
    :styles="styles"
  >
    <!-- <input placeholder="Text here" type="search" class="form-control"> -->
    <div slot="suggestion-item" slot-scope="scope">
      <span v-html="boldenSuggestion(scope)"></span>
    </div>
  </VueSimpleSuggest>
</template>

<script>
// import VueSimpleSuggest from "vue-simple-suggest";
import { GeneralService } from "../GeneralService";
// import "vue-simple-suggest/dist/styles.css"; // Using a css-loader
import { debounce } from "lodash";

export default {
  components: {
    // VueSimpleSuggest
  },
  props: {
    value: {}
  },
  data: () => ({
    val: "",
    list: [],
    setBySuggestionClick: "",
    styles: {
      defaultInput: "form-control"
    }
  }),
  created() {},
  computed: {
    valueChild: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    setText(val) {
      this.$refs.suggestComponent.setText(val);
    },
    reset() {
      this.$refs.suggestComponent.setText("");
    },
    suggClick() {
      // this.setBySuggestionClick = e;
    },
    boldenSuggestion(scope) {
      if (!scope) return scope;
      const { suggestion, query } = scope;
      let result = this.$refs.suggestComponent.displayProperty(suggestion);
      if (!query) return result;
      const texts = query.split(/[\s-_/\\|.]/gm).filter(t => !!t) || [""];
      return result.replace(
        new RegExp("(.*?)(" + texts.join("|") + ")(.*?)", "gi"),
        "$1<b>$2</b>$3"
      );
    },
    getList(search) {
      return new Promise(rsv => {
        GeneralService.getBrands({ search }).then(res => {
          rsv(res.data.map(x => x.name));
          // this.list = res.data.map(x => x.name);
          // this.$refs.suggestComponent.suggestions = this.list;
          // this.$refs.suggestComponent.showList();
        });
      });
    },

    find(search) {
      if (search) this.asyncFind(search, this);
    },
    asyncFind: debounce((search, vm) => {
      vm.isLoading = true;
      vm.getList(search);
    }, 350)
  }
};
</script>

<style></style>
