<template>
  <select class="custom-select" v-model="valueChild">
    <option :value="undefined" v-if="all">TODOS</option>
    <option v-for="(l, i) in list" :key="i" :value="i">{{l}}</option>
  </select>
</template>

<script>
export default {
  props: {
    // typeId: {
    //   default: 1,
    //   required: true
    // }
    value: {
      required: true
    },
    all: {
      default: false
    }
  },
  // data () {
  //   list: []
  // },
  // created () {
  //   list = this.$store.state.general.products.types
  //   list = list ? list : [];
  //   this.list = list
  // }
  computed: {
    list() {
      return this.$store.state.config.products
        ? this.$store.state.config.products.types
        : [];
    },
    valueChild: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    }
  }
  // computed: {
  // types () {
  //   var list = this.$store.state.general.products.types
  //   list = list ? list : [];
  //   return list
  // }
  // }
};
</script>

<style>
</style>
