x<template>
  <div>
    <h4>PRODUCTOS Y SERVICIOS</h4>
    <app-table-registers
      ref="tr"
      :getList="getList"
      @btnNew="
        $refs.formProduct.reset();
        $refs.dFormProduct.show();
      "
    >
      <template slot="tl">
        <div class="col form-inline">
          <button
            class="btn btn-primary mr-2"
            @click="
              $refs.formProduct.reset();
              $refs.dFormProduct.show();
            "
          >
            <i class="fa fa-plus"></i> Nuevo
          </button>
          <SelectType :all="true" v-model="type_id"></SelectType>
          <!-- <div class="form-inline ml-2">
            <SelectCategorie
              ref="selCat"
              v-model="s_categorie_id"
            ></SelectCategorie>
          </div> -->
        </div>
      </template>
      <template slot="table">
        <thead>
          <tr>
            <th>ID</th>
            <th></th>
            <th>NOMBRE</th>
            <th>FUENTE DE INGRESO</th>
            <th>TIPO</th>
            <th class="text-right">PRECIO UNITARIO</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in list.data" :key="l.id">
            <td>{{ l.id }}</td>
            <td>
              <app-img-viewer
                style="width: 70px;"
                v-if="l.file_id"
                :src="urlToImages + l.file_id"
              ></app-img-viewer>
            </td>
            <td>
              <div>{{ l.name }}</div>
              <span class="badge badge-light" v-show="l.brand_name"
                >{{ l.brand_name }}
              </span>
            </td>
            <td>{{ l.source_name }}</td>
            <td>{{ types[l.type_id] }}</td>
            <td class="text-right">
              <app-span-money
                :quantity="l.unit_price"
                :moneyCode="l.money_code"
              ></app-span-money>
            </td>
            <td>
              <div class="btn-group">
                <button
                  class="btn btn-light btn-sm"
                  @click="
                    $refs.formProduct.loadReg(l);
                    $refs.dFormProduct.show();
                  "
                >
                  <i class="fa fa-edit"></i>
                </button>
                <button
                  class="btn btn-light btn-sm"
                  @click="
                    $refs.myn.val = l;
                    $refs.myn.show();
                  "
                >
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>

    <app-modal-basic idModal="dFormProduct" ref="dFormProduct">
      <h4>FORMULARIO DE PRODUCTO</h4>
      <FormProduct
        ref="formProduct"
        @submitted="
          $refs.tr.gl();
          $refs.dFormProduct.hide();
        "
      ></FormProduct>
    </app-modal-basic>

    <app-modal-yn
      ref="myn"
      @no="$refs.myn.hide()"
      @yes="
        deleteItem($refs.myn.val.id);
        $refs.myn.hide();
      "
    >
      <p v-if="$refs.myn && $refs.myn.val">
        Seguro de eliminar el producto
        <strong>{{ $refs.myn.val.name }}</strong>
        ?
      </p>
    </app-modal-yn>
  </div>
</template>

<script>
import FormProduct from "./Form";
import SelectType from "./SelectType";
import { StoreService } from "../service";

export default {
  components: {
    // SelectCategorie,
    FormProduct,
    SelectType
  },
  props: {
    categorie_id: {
      default: null
    },
    init_categorie: {
      default: null
    },
    area_id: {
      default: null
    }
  },
  mounted() {},
  data: () => ({
    s_categorie_id: null,
    list: {},
    type_id: undefined,
    types: { 1: "BIENES", 2: "SERVICIOS" }
  }),
  watch: {
    type_id() {
      this.$refs.tr.gl();
    },
    area_id() {
      this.$refs.tr.gl();
    },
    categorie_id() {
      this.$refs.tr.gl();
    }
  },
  computed: {
    urlToImages() {
      return process.env.VUE_APP_API_URL + "files/";
    }
  },
  methods: {
    deleteItem(reg_id) {
      StoreService.deleteProduct(reg_id).then(() => this.$refs.tr.gl());
    },
    getList({ page, search }) {
      return new Promise(rsv => {
        StoreService.getProducts({
          page,
          search,
          type_id: this.type_id,
          categorie_id: this.categorie_id,
          area_id: this.area_id
        }).then(res => {
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style></style>
