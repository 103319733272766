<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>Tipo de Producto</label>
      <SelectType
        required
        v-model="reg.type_id"
        :class="{ 'is-invalid': errors.type_id }"
      ></SelectType>
    </div>
    <div class="form-group">
      <label for>Nombre</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': errors.name }"
        v-model="reg.name"
        required
      />
    </div>
    <div class="form-group">
      <label for>Codigo</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': errors.code }"
        v-model="reg.code"
        required
      />
    </div>
    <div class="form-group">
      <label for>Categoria</label>
      <SelectCategorie
        ref="setcat"
        v-model="reg.categorie_name"
      ></SelectCategorie>
    </div>
    <div class="form-group">
      <label for>Precio Unitario</label>
      <app-input-money
        ref="inputMoney"
        :moneyCode.sync="reg.money_code"
        :quantity.sync="reg.unit_price"
      ></app-input-money>
    </div>

    <div class="form-group">
      <label for>Marca</label>
      <SelectBrand ref="selBra" v-model="reg.brand_name"></SelectBrand>
    </div>

    <div class="form-group">
      <label for>Unidad de Medida</label>
      <!-- <SelectBrand ref="selBra" v-model="reg.brand_name"></SelectBrand> -->
      <SelectMeasurement
        ref="selMea"
        v-model="reg.measurement_name"
      ></SelectMeasurement>
    </div>
    <div class="form-group">
      <label for>Unidades x paquete</label>
      <app-input-number v-model="reg.units_by_pack" step="1"></app-input-number>
    </div>

    <div class="form-group">
      <label for>Area</label>
      <SelectArea v-model="reg.area_id"></SelectArea>
    </div>
    <div class="form-group">
      <label for>Fuente de Ingreso</label>
      <SelectSource
        ref="setsou"
        v-model="reg.source_id"
        :area_id="reg.area_id"
      ></SelectSource>
      <!-- <AdmSourceSelect ref="sourceSelect" :itemId.sync="reg.source_id"></AdmSourceSelect> -->
    </div>

    <div class="form-group">
      <label for>Imagen de referencia</label>
      <!-- <app-input-file id="customFile1" ref="if" @change="files = $event"></app-input-file> -->
      <br />
      <input
        type="file"
        accept="image/*"
        @change="files = $event.target.files"
        ref="inFile"
      />
      <!-- <div class="custom-file">
        <input type="file" class="custom-file-input" id="productImageId" required @change="files = $event">
        <label class="custom-file-label" for="productImageId">Seleccionar una imagen</label>
        <div class="invalid-feedback">Example invalid custom file feedback</div>
      </div>-->
    </div>

    <!-- <div v-if="files.length > 0" class="form-group">
      <span v-for="(l, index) in files" :key="index">{{ l.name }} </span>
    </div>-->
    <!-- <div class="form-group">
      <button class="btn btn-primary">Guardar</button>
    </div>-->
    <app-button-submit :disabled="errors"></app-button-submit>
  </form>
</template>

<script>
import SelectType from "./SelectType";
import SelectCategorie from "../../general-module/categories/InputSelect";
import SelectArea from "../../admin-module/areas/Select";
import SelectSource from "../../admin-module/sources/Select";
import * as validate from "validate.js";
import SelectBrand from "../../general-module/brands/Select";
import SelectMeasurement from "../../general-module/measurements/Select";
import { StoreService } from "../StoreService";

const formRules = {
  type_id: { presence: { message: "Requerido" } },
  name: { presence: { message: "Requerido" } },
  code: { presence: { message: "Requerido" } },
  // categorie_id: { presence: { message: "Requerido" } },
  money_code: { presence: { message: "Requerido" } },
  unit_price: { presence: { message: "Requerido" } }
};

export default {
  components: {
    SelectType,
    SelectCategorie,
    SelectArea,
    SelectSource,
    SelectBrand,
    SelectMeasurement
  },
  computed: {
    errors() {
      let err = validate(this.reg, formRules, { fullMessages: false });
      return err ? err : false;
    }
  },
  props: {},
  data() {
    return {
      reg: {},
      files: []
    };
  },
  methods: {
    reset() {
      this.reg = {
        units_by_pack: 1
      };
      this.$refs.selBra.reset();
      this.$forceUpdate();
      this.$refs.inputMoney.setDefault();
      this.$refs.setcat.reset();
      this.$refs.setsou.reset();
      this.$refs.selMea.reset();
      this.files = [];
      if (this.$refs.inFile) this.$refs.inFile.value = "";
    },
    save() {
      let formData = new FormData();
      for (let i in this.reg) {
        if (this.reg[i] !== undefined && this.reg[i] !== null)
          formData.append(i, this.reg[i]);
      }
      for (let i = 0; i < this.files.length; i++) {
        formData.append(["file_" + i], this.files[i]);
      }

      if (this.reg.id === undefined) {
        StoreService.saveProduct(formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then((res) => this.$emit("submitted", res));
      } else {
        StoreService.saveProduct(formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then(() => this.$emit("submitted", this.reg.id));
      }
    },
    loadReg(reg) {
      this.reg = JSON.parse(JSON.stringify(reg));
      // this.$refs.setcat.setValueFromId(this.reg.categorie_id);
      this.$refs.setcat.setText(reg.categorie_name);
      this.$refs.setsou.setValueFromId(this.reg.source_id);
      this.$refs.selBra.setText(reg.brand_name);
      this.$refs.selMea.setText(reg.measurement_name);
    }
  }
};
</script>

<style scoped></style>
